import { useCallback, useState } from 'react';
import { COLORS } from './App.js';

function Grid({ values, width, height, currColorIndex, setGrid }) {
    const [isMouseDown, setIsMouseDown] = useState(false);

    const style = { flexBasis: 100 / width + '%', height: 100 / height + '%' };

    const updateCell = useCallback((i,j, currColorIndex) => {
        var newValues = values.map(function(arr) {
            return arr.slice();
        });
        newValues[i][j].color = currColorIndex;
        setGrid(newValues);
    }, [values]);

    const onMouseDown = useCallback((e,i,j, currColorIndex) => {
        setIsMouseDown(true);
        updateCell(i,j, currColorIndex);
    }, [updateCell]);

    const onMouseUp = useCallback(() => {
        setIsMouseDown(false);
    }, []);
    const onMouseMove = useCallback((e,i,j, currColorIndex) => {
        if (!isMouseDown) return;
        updateCell(i,j, currColorIndex);
    }, [isMouseDown,updateCell]);

    return <div className="grid"  onMouseUp={e => onMouseUp()} >
        {
            values.map(
                (row, i) => {
                    return row.map(
                        (cell, j) => <div style={{backgroundColor: COLORS[cell.color], ...style}} className="cell" key={"key_"+i+"_"+j} onMouseDown={e => onMouseDown(e, i, j, currColorIndex)} onMouseMove={e => onMouseMove(e, i, j, currColorIndex)} />
                    );
                }
            )
        }
    </div>;

}

export default Grid;