import {CirclePicker} from 'react-color'; 
import {COLORS} from './App.js';
import {useCallback} from 'react';

function Options({width, height, setHeight, setWidth, currColorIndex, setCurrColorIndex}) {

    const setColorIdx = useCallback((e) => {
        if (e == null) { 
            return;
        }
        const idx = COLORS.indexOf(e.hex.toUpperCase());
        setCurrColorIndex(idx);
    }, []);
    return <div className="options">
        <label htmlFor="width">Width: ({width})</label>
        <input type="range" name="width" value={width} min="2" max="50" step="1" onChange={e => { setWidth(parseInt(e.target.value)); }}></input>
        <label htmlFor="height">Height: ({height})</label>
        <input type="range" name="height" value={height} min="2" max="50" step="1" onChange={e => { setHeight(parseInt(e.target.value)); }}></input>
        <CirclePicker colors={COLORS} onChange={e => setColorIdx(e)} value={COLORS[currColorIndex]} />
        
    </div>
}

export default Options;