import Grid from './Grid.js';
import Options from './Options.js';
import './App.css';
import { useEffect, useState } from 'react';

export const COLORS = ["#FFFFFF", "#000000", "#FF0000", "#00FF00", "#0000FF"];

function App() {

  const [width, setWidth] = useState(10);
  const [height, setHeight] = useState(10);

  const [grid, setGrid] = useState(getEmptyGrid(width, height));
  const [currColorIndex, setCurrColorIndex] = useState(0);

  useEffect(() => {

    setGrid(getEmptyGrid(width, height));
  }, [width, height]);

  return (
    <div className="App">
      <span className="title">Color By Math Worksheet Generator</span>
      <div className="main">
        <Grid values={grid} width={width} height={height} currColorIndex={currColorIndex} setGrid={setGrid} />
        <Options width={width} height={height} setHeight={setHeight} setWidth={setWidth} currColorIndex={currColorIndex} setCurrColorIndex={setCurrColorIndex} />
      </div>
    </div>
  );
}

function getEmptyGrid(w, h) {
  const initGrid = [];
  for (let i = 0; i < w; i++) {
    initGrid.push([]);
    for (let j = 0; j < h; j++) {
      initGrid[i][j] = {
        'color': 0,
      };
    }
  }
  return initGrid;
}

export default App;
